<template>
  <div class="page-table projeTanim  mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">
      {{ $t("src.views.apps.tutanak.tanimlama.title") }}
    </div>
    <el-row :gutter="10" v-loading="loading || projeLoading || sozlesmeLoading"
            :element-loading-text='loading ? $t("src.views.apps.proje.tanimlama.addLoading") : projeLoading ? $t("src.views.apps.proje.liste.loading") : $t("src.views.apps.sozlesme.liste.loading")'
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="tutanakForm" :rules="rulesProje" ref="tutanakForm" class="demo-ruleForm">
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-10 pt-10">
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Firma Adı" prop="musteriID">:
                  <el-select style="width: 100%" size="small" auto-complete="off" v-model="tutanakForm.musteriID"
                             placeholder="Firma Seçiniz" filterable :filter-methos="filterMethod"
                             @change="getProjeListesi">
                    <el-option v-for="item in musteriler" aria-autocomplete="none" :key="item.musteriID"
                               :label="item.musteriAdi"
                               :value="item.musteriID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p"
                      v-if="musteriSecim2 || musteriSecim && projeler.length != 0">
                <el-form-item label="Proje Adı" prop="projeID">:
                  <el-select style="width: 97%; text-align: center;" size="small" v-model="tutanakForm.projeID"
                             @change="getSozlesmeListesi" placeholder="Proje Seçiniz" filterable
                             :filter-methos="filterMethod">
                    <el-option
                        v-for="item in projeler"
                        :key="item.projeID"
                        :label="item.projeBaslik"
                        :value="item.projeID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p" v-if="projeSecim && sozlesmeler.length != 0">
                <el-form-item label="Sözleşme Adı (Genel konu ise seçmeyiniz)">:
                  <el-select style="width: 97%; text-align: center;" size="small" v-model="tutanakForm.sozlesmeID"
                             placeholder="Sözleşme Seçiniz" filterable :filter-methos="filterMethod">
                    <el-option
                        v-for="item in sozlesmeler"
                        :key="item.sozlesmeID"
                        :label="item.sozlesmeBaslik"
                        :value="item.sozlesmeID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="16" :md="16" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Konu" prop="konu">:
                  <el-input size="small"
                            auto-complete="off"
                            v-model="tutanakForm.konu"
                            :placeholder="'Konu Giriniz'">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Kısa Açıklama" prop="kisaAciklama">:
                  <el-input type="textarea" auto-complete="off" :autosize="{ minRows: 1, maxRows: 6 }"
                            placeholder="Kısa Açıklama" v-model="tutanakForm.kisaAciklama">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İçerik" prop="icerik">:
                  <el-input type="textarea" auto-complete="off" :autosize="{ minRows: 2, maxRows: 6 }"
                            placeholder="İçerik" v-model="tutanakForm.icerik">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item v-if="tutanakForm.musteriID" class="mt-10">
                  <el-checkbox v-model="tutanakForm.bildiri">Bildiri yapılsın mı?</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :lg="6" :md="6" :sm="24" :xs="24">
          <el-card class="tutanak-right-area">
            <el-row :gutter="5" style="padding: 0 15px 0 15px">
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.tutanak.tanimlama.yetkili")' prop="yetkili">
                  <el-autocomplete
                      prop="yetkili"
                      size="small"
                      class="d-block"
                      v-model="tutanakForm.yetkiliAdSoyad"
                      :fetch-suggestions="querySearchAsync"
                      :placeholder="'Yetkili Ad Soyad Giriniz'"
                      clearable
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="5" style="padding: 0 15px 0 15px">
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.tutanak.tanimlama.sayi")' prop="sayi">
                  <el-input size="small" auto-complete="off" v-model="tutanakForm.sayi"
                            :placeholder="'Sayi Giriniz'">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="5" style="padding: 0 15px 0 15px">
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.tutanak.tanimlama.yer")'>
                  <el-input size="small" auto-complete="off" v-model="tutanakForm.yer"
                            :placeholder="'Yer Giriniz'">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İletişim Türü" prop="iletisimTuru">
                  <el-select size="small" style="width: 100%;" v-model="tutanakForm.iletisimTuruID"
                             placeholder="İletişim Türü Seçiniz" filterable :filter-methos="filterMethod">
                    <el-option
                        v-for="item in iletisimTuru"
                        :key="item.key"
                        :label="item.label"
                        :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-button size="mini" v-on:click="tutanakTanimla('tutanakForm')" class="onayBtn"
                         style="margin-top: 10px;"
                         type="primary">
                <label>Kaydet</label>
              </el-button>
              &nbsp;
              <el-popover placement="bottom" width="300" v-model="visibleSil">
                <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="primary" @click="resetForm('tutanakForm')">
                    {{ $t('src.views.apps.genel.yes') }}
                  </el-button>
                  <el-button size="mini" @click="visibleSil = false;">
                    {{ $t('src.views.apps.genel.no') }}
                  </el-button>
                </div>
                <el-button size="mini" slot="reference">
                  {{ $t('src.views.apps.genel.temizle') }}
                </el-button>
              </el-popover>
            </el-row>
          </el-card>
        </el-col>
        <el-col v-if="tutanakForm.bildiri" :lg="18" :md="18" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInDown pb-10 pt-10 mt-10">
            <el-row class="pt-10">
              <el-col :span="24" class="col-p">
                <el-form-item label="Katılımcılar">:
                  <el-select no-data-text="Seçilen firmaya ait görevli personel bulunamadı" multiple style="width: 100%"
                             size="small" auto-complete="off"
                             v-model="tutanakForm.katilimcilar"
                             placeholder="Kişi Seçiniz" filterable>
                    <el-option v-for="item in userList" aria-autocomplete="none" :key="item.ID" :label="item.adSoyad"
                               :value="item.yoneticiID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" class="col-p">
                <el-form-item label="Firma Yetkilileri">:
                  <el-select no-data-text="Seçilen firmaya ait Yetkili bulunamadı" multiple style="width: 100%"
                             size="small" auto-complete="off"
                             v-model="tutanakForm.yetkililer"
                             placeholder="Kişi Seçiniz" filterable>
                    <el-option v-for="item in yetkiliList" aria-autocomplete="none" :key="item.musteriDetayID"
                               :label="item.yetkiliAdSoyad"
                               :value="item.musteriDetayID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
import musteriService from '../../../WSProvider/MusteriService'
import projeService from '../../../WSProvider/ProjeService'
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'
import CakismaDialog from '@/components/Cakisma'
import tutanakService from "@/WSProvider/TutanakService";
import yoneticiService from "@/WSProvider/YoneticiService";

var moment = require('moment');
let time = moment().format('YYYY-MM-DD');

export default {
  name: "TutanakTanimla",
  components: {CakismaDialog},
  computed: {
    tutanakStore() {
      return this.$store.getters.getTutanakData
    }
  },
  created() {
    this.checkDevice();
    if (this.tutanakStore.projeID) {
      this.tutanakForm.projeID = this.tutanakStore.projeID
      this.musteriSecim2 = true
    }
    if (this.tutanakStore.musteriID) {
      this.getProjeListesi(this.tutanakStore.musteriID)
      this.tutanakForm.musteriID = this.tutanakStore.musteriID
    }
    EventBus.$on("musterilistesi", () => {
      this.getMusteriListesi();
    })
  },
  mounted() {
    window.addEventListener('resize', this.checkDevice);
    this.getMusteriListesi();
  },
  beforeDestroy() {
    this.$store.commit("setTutanakData")
    window.removeEventListener('resize', this.checkDevice);
  },
  data() {
    var yetkiliValidate = (rule, value, callback) => {
      if (this.tutanakForm.yetkiliAdSoyad === '') {
        callback(new Error('Lütfen Yetkili Giriniz'));
      } else {
        callback();
      }
    };
    var iletisimValidate = (rule, value, callback) => {
      if (this.tutanakForm.iletisimTuruID === '') {
        callback(new Error('Lütfen İletişim Türü Seçiniz'));
      } else {
        callback();
      }
    };
    return {
      isDesktop: false,
      grupSwitchState: false,
      kontrol: false,
      musteriSecim: false,
      musteriSecim2: false,
      projeSecim: false,
      allSubArraysEmpty: false,
      loading: false,
      ilLoading: false,
      ilceLoading: false,
      projeLoading: false,
      sozlesmeLoading: false,
      tumunuSec: false,
      musteriler: [],
      projeler: [],
      userList: [],
      yetkiliList: [],
      sozlesmeler: [],
      sozlesmeList: [],
      sozlesme: "",
      baslamaTarihi: "",
      bitisTarisi: "",
      cakisma: "",
      editor: ClassicEditor,
      editorConfig: {
        language: 'tr',
      },
      activeCollapse: ['1'],
      visibleSil: false,
      options: [],
      dateRangeOptions: {
        firstDayOfWeek: 1
      },
      seciliSozlesmeler: [],
      sozlesmeTarihBaslikGoster: false,
      tutanakForm: {
        tur: 10, //proje -> 10, etut -> 20
        kategori: '',
        musteriAdi: '',
        projeBaslik: '',
        konu: '',
        musteriID: '',
        projeID: '',
        sozlesmeID: '',
        iletisimTuruID: '',
        yetkiliAdSoyad: '',
        sayi: '',
        yer: '',
        bildiri: false,
        icerik: '',
        kisaAciklama: '',
        iletisimTarihi: time,
        durum: '1',
        sozlesmeAtamalari: [],
        katilimcilar: [],
        yetkililer: [],
      },
      selectIcon: '1',
      page: 0,
      postPage: 0,
      sizes: 10,
      rulesProje: {
        yetkili: [{
          validator: yetkiliValidate,
          required: true,
          message: "Lütfen Yetkili Giriniz",
          trigger: 'blur'
        }],
        iletisimTuru: [{
          validator: iletisimValidate,
          required: true,
          message: "Lütfen İletişim Türünü Seçiniz",
          trigger: 'blur'
        }],
        sayi: [{
          required: true,
          message: "Lütfen Sayı Giriniz",
          trigger: 'blur'
        }],
        projeID: [{
          required: true,
          message: "Lütfen Proje Seçiniz",
          trigger: 'blur'
        }],
        musteriID: [{
          required: true,
          message: "Lütfen Firma Seçiniz",
          trigger: 'blur'
        }],
        icerik: [{
          required: true,
          message: "Lütfen İçerik Giriniz",
          trigger: 'blur'
        }],
        kisaAciklama: [{
          required: true,
          message: "Lütfen Kısa Açıklama Giriniz",
          trigger: 'blur'
        }],
        konu: [{
          required: true,
          message: "Lütfen Konu Giriniz",
          trigger: 'blur'
        }],
        katilimcilar: [{
          required: true,
          message: "Katılımcılar",
          trigger: 'blur'
        }],
      },
      iletisimTuru: [
        {
          key: '1',
          label: 'Telefon',
        },
        {
          key: '2',
          label: 'E-Posta',
        },
        {
          key: '3',
          label: 'Toplantı',
        },
        {
          key: '4',
          label: 'Yüz Yüze',
        },
      ],
    }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      if (queryString.length >= 3) {
        yoneticiService.yoneticiFilter(queryString, '', '', '', '', '', '', 1, 0, 100).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            const data = response.data.map(item => {
              return {
                value: item.adSoyad,
                link: item.adSoyad,
              }
            })
            cb(data)
          } else cb([])
        }).catch(() => cb([]))
      } else cb([])
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    datePickerOptions(baslamaTarihi) {
      return {
        firstDayOfWeek: 1,
        disabledDate: date => {
          return date < new Date(baslamaTarihi);
        }
      };
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
      this.getMusteriListesi();
    },
    getMusteriListesi() {
      try {
        this.loading = true;
        musteriService.musteriListesi(this.selectIcon, this.postPage, 1000).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.musteriler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.musteriler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getProjeListesi(val) {
      this.userList = []
      this.yetkiliList = []
      this.tutanakForm.katilimcilar = []
      this.tutanakForm.yetkililer = []
      this.getUserList(val);
      this.getFirmaYetkilileri(val);
      this.musteriSecim = true
      try {
        musteriService.musteriProjeleri("", "", val || this.tutanakForm.musteriID, 1).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.count && response.count != 0) {
            this.projeler = response.data;
            if (this.tutanakForm.projeID) this.musteriSecim = true
          }
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.projeler = [];
            } else if (error.status == 503) {
              this.projeler = []
              this.musteriSecim = false
              notification.Status("warning", this, "Bu müşterinin projesi bulunmamaktadır.")
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.projeLoading = false
      }
    },
    getSozlesmeListesi() {
      this.projeSecim = true
      try {
        this.sozlesmeLoading = true;
        projeService.projeSozlesmeleri(this.tutanakForm.projeID).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.sozlesmeler = response.data;
          }
          this.sozlesmeLoading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.sozlesmeler = [];
            } else if (error.status == 503) {
              this.sozlesmeler = []
              this.projeSecim = false
              notification.Status("warning", this, "Bu projenin sözleşmesi bulunmamaktadır.")
            } else notification.Status("errorMsg", this, error.errMsg)
          }
          this.sozlesmeLoading = false;
        })
      } catch (e) {
        this.sozlesmeLoading = false
      }
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
    },
    getUserList(musteriID) {
      this.loading = true;
      yoneticiService.musterideGorevliPersoneller(musteriID).then((response) => {
        if (response.status == 200) {
          localStorage.setItem("userDataBGSurec", response.token)
          this.userList = response.data
        }
        this.loading = false;
      }).catch(err => {
        if (err.responseJSON) {
          let error = err.responseJSON
          if (error.status == 401) {
            notification.Status(150, this);
          } else if (error.status == 404) {
            this.userList = [];
          } else notification.Status("errorMsg", this, error.errMsg)
        } else {
          //notification.Status(503, this)
        }
        this.loading = false;
      })
    },
    getFirmaYetkilileri(musteriID) {
      this.loading = true;
      musteriService.musteriDetay(musteriID).then((response) => {
        if (response.status == 200) {
          localStorage.setItem("userDataBGSurec", response.token)
          this.yetkiliList = response.data.ekBilgiler
        }
        this.loading = false;
      }).catch(err => {
        if (err.responseJSON) {
          let error = err.responseJSON
          if (error.status == 401) {
            notification.Status(150, this);
          } else if (error.status == 404) {
            this.userList = [];
          } else notification.Status("errorMsg", this, error.errMsg)
        } else {
          //notification.Status(503, this)
        }
        this.loading = false;
      })
    },
    tutanakTanimla(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          try {
            this.projeLoading = true;
            const pf = this.tutanakForm
            tutanakService.tutanakTanimla(pf.musteriID, pf.bildiri, pf.iletisimTuruID, pf.durum, pf.projeID, pf.konu, pf.kisaAciklama, pf.icerik, pf.sayi, pf.yer, pf.katilimcilar, pf.yetkiliAdSoyad, pf.sozlesmeID, pf.yetkililer).then((response) => {
              localStorage.setItem("userDataBGSurec", response.token)
              if (response.status === 200) {
                EventBus.$emit("tutanakListesi", true);
                this.resetForm(formName)
                notification.Status("success", this, response.msg)
                functions.sayfaKapat("tutanaktanimla", this);
              }
              // this.projeLoading = false;
            }).catch(err => {
              if (err.responseJSON) {
                let error = err.responseJSON
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 503) {
                  this.musteriSecim = false
                  notification.Status("warning", this, "Bu müşterinin projesi bulunmamaktadır.")
                } else notification.Status("errorMsg", this, error.errMsg)
              } else {
                //notification.Status(503, this)
              }
              this.projeLoading = false;
            })
          } catch (e) {
            // notification.Status("success", this, "An error occurred while fetching the customer list.")
            this.projeLoading = false
          }
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.addLoading = false
          }
        }
      })
    },
  },
}

</script>
<style scoped>
.tutanak-right-area .el-card__body {
  padding: 0 !important;
}
</style>
<style lang="scss">
.sozlesmeSelect {
  .el-row {
    padding: 10px 10px 10px 8px;
  }

  .el-row:nth-child(2n) {
    background: #f3f7fa !important
  }
}

</style>
<style>
.flex-box {
  display: flex;
  justify-content: flex-end;
}

.kapsamlar .el-collapse-item .el-collapse-item__header {
  color: white;
  text-align: center;
  font-size: 15px !important;
  padding: 0px 10px;
  font-weight: 400;
  border-radius: 8px;
}

.kapsamlar .el-collapse-item:nth-child(1) .el-collapse-item__header {
  background-color: #28323b;
}

.kapsamlar .el-collapse-item:nth-child(2) .el-collapse-item__header {
  background-color: #006cae;
}

.kapsamlar .el-collapse-item:nth-child(3) .el-collapse-item__header {
  background-color: #3595cf;
}

.kapsamlar .el-collapse-item:nth-child(4) .el-collapse-item__header {
  background-color: #ff8b00;
}

.el-collapse-item__content {
  padding-bottom: 5px;
}

.d-block {
  display: block;
}
</style>
